:root{
    --yellow:#ffee93;
    --contrast: rgb(111, 241, 241);
    --dark: #732c2c;
    --superdark: #420c14;
    --highlight: #E87461;
    --lighttext: rgb(255, 255, 255);
    --light: rgb(246, 147, 226);
}
.App {
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: 1fr;
}
.modal-open{
    overflow: hidden;
    height:100vh;
}
.content{
    /* margin-left:150px; */
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
    grid-column: 2;
    width: 100%;
}
.container{
    margin:25px;
    border: 10px solid var(--superdark);
    border-radius:15px;
    max-width: 1050px;
    display: grid;
}

h2{
    font-weight: 400;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.background{
    height:100%;
    width:100%;
}

/* button design */
.btn{
    background-color: var(--highlight);
    border: 3px solid var(--dark);
    text-decoration: none;
    padding: 5px;
    border-radius: 10px;
    color:var(--superdark);
    margin:5px;
}
.btn:hover{
    font-size: large;
}

/* modals */
.backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 100;
  }
  .modal-body{
    background-color: white;
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    /* border: 3px solid white; */
    overflow-y: auto;
  }

@media (max-width:780px) {
    .backdrop{
        width:100%
    }
    .modal-body{
        max-width:85%
    }
    /* .modal-body p {
        width: 100%;
        font-size: 1.2rem;
    }
    .modal-body h2 {
        font-size: 1.4rem;
        margin: 10px;
    } */
}
div#mountains-clouds {
    width: 100%;
}

img#backgroundm {
    width: 100%;
    overflow: hidden;
    overflow-y: clip;
}

div#mountains-clouds {
    display: flex;
    justify-content: center;
}

div#mountains-clouds img {
    /* bottom: 0; */
    position: absolute;
}

img#backgroundm {
    z-index: 0;
    bottom: -5px;
    width: 120%;
}

img#mountain2 {
    left: 35px;
    z-index: 1;
    height: 33%;
    bottom: 0;
}

img#mountain1 {
    height: 34%;
    bottom: 0;
    z-index: 2;
    right: -23px;
}

img#cloud2 {
    left: 75px;
    bottom: 250px;
    height: 65px;
}
img#cloud1 {
    bottom: 179px;
    height: 62px;
    left: 357px;
} 
img#cloud3 {
    right: 75px;
    bottom: 245px;
    height: 76px;
}
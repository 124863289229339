.projectmodal{
    border: 10px solid var(--superdark);
    border-radius:15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.projectmodal h2{
    font-size: xx-large;
}
.projectmodal img{
    width:80%;
}
.projectmodal p{
    font-size: large;
    width:80%;
    max-width: 800px;
    white-space: pre-wrap;
}
.projectmodal-buttons{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:80%
}
.projectmodal-notes{
    background-color: rgb(255, 216, 222);
    border:2px solid var(--dark);
    color: var(--dark);
    padding:5px;
    text-align: center;
}
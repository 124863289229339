.contact{
    min-height: 90vh;
    background-color: var(--light);
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.contact-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    border: 3px solid var(--superdark);
    margin:10px;
    margin-top:15%;
    padding: 10px;
    font-size: large;
}

.contact-info p{
    opacity: 100%;
}

.contact-socialmedia{
    display: flex;
    width:100%;
    justify-content: center;
}

.contact-socialmedia a{
    color:black;
    font-size: xx-large;
    margin: 0 10px;
}
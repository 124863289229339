
.city img {
    position: absolute;
}

img#background, img#background2 {
    bottom: 0%;
    z-index: 0;
    width: 50%;
}

img#background {
    left: 0;
}

img#background2 {
    right: 0;
}

img#city2 {
    bottom: -6%;
    z-index: 1;
    right: 0;
    width: 30%;
}

img#city1 {
    width: 30%;
    bottom: -6%;
    z-index: 1;
    left: 4%;
}
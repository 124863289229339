
.landing{
    min-height: 90vh;
    background-color: var(--contrast);
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.nametag{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    z-index: 5;
}
.nametag img{
    height:100px;
    width:auto;
}
.landing h1{
    font-weight:300;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: var(--superdark);
    margin-top: 0px;
}
.menu{
    position: fixed;
    height: 100vh;
    margin-top:0;
    /* list-style: none; */
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    max-height:70%;

}

.menu-item{
    font-weight:600;
    color:var(--dark);
    font-size: large;
}

.menu-item:hover{
    cursor: pointer;
    font-size: x-large;
}
div#buttonContainer {
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    z-index: 6;
}
#buttonContainer button {
    border: none;
    background: var(--superdark);
    width: 100%;
    text-align: center;
    padding: 1rem;
    color: var(--highlight);
    font-size: 1.5rem;
}

#buttonContainer .menu-open {
    color: var(--lighttext);
}

/* .menu-item.active{
    color:var(--highlight);
}

.menu-item.active:hover{
    cursor: default;
    font-size: large;
} */

@media (max-width:780px){
    .App {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 150px auto 200px;
        min-width: 100%;
        overflow: hidden;
    }
    .menu {
        position: sticky;
        top: 0;
        z-index: 10;
        margin-top: 0;
        display: flex;
        list-style: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-row-start: 1;
        max-width: 100%;
        background: var(--highlight);
        padding: 0.5rem;
        height: auto;
    }
    .content {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        grid-column: 1;
        width: 100%;
        grid-row-start: 2;
        margin-top: -rem;
    }
    .content .container {
        min-height: 70vh;
    }
    .nametag, .nametag img {
        max-width: 100%;
        padding: .5rem;
    }
    .tv__top, .tv {
        display: none;
    }
    .project-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer {
        grid-row: 3;
    }
    .footer-right {
        display: none;
    }
    .footer .footer-left {
        width: 100%;
        align-items: center;
    }
    .footer-left div {
        padding: 1rem;
        text-align: center;
    }
    .menu-item {
        margin-bottom: 10px;
    }
} 
/* .forest { */
    /* background-color: rgb(153, 204, 186); */
    /* width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: flex-end;
} */

.forest img {
    position: absolute;
}

img#hill2 {
    height: 60%;
    left: -18%;
    bottom: -26%;
    min-width: 87%;
}

img#hill1 {
    height: 68%;
    right: -38%;
    bottom: -38%;
    z-index: 1;
    min-width: 120%;
}

img#treegroup1 {
    height: 200px;
    z-index: 2;
    bottom: 15%;
    left: 12%;
}

img#treegroup2 {
    height: 301px;
    z-index: 2;
    bottom: 0%;
    left: 78%;
}
.footer{
    height: 200px;
    width: 100vw;
    background-color: var(--superdark);
    border-top: 10px solid var(--highlight);
    color: var(--highlight);
    display:flex;
    flex-direction: column;
}

.footer-socialmedia{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    
}
.footer-socialmedia a{
    color:var(--highlight);
    font-size: xx-large;
    margin: 0 10px;
}

.footer-low{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-left{
    width:45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.footer-left div{
    padding-right: 20px;;
}
.footer-left img{
    width:200px;
    margin-bottom: 10px;
}
.footer-left p{
    margin:5px 0;
}
.footer-email:hover{
    cursor: pointer;
}



.footer-right{
    width:45%;
    border-left:1px solid var(--highlight);
}
.footer-right li{
    margin: 5px 0;
}
.footer-right li:hover{
    cursor: pointer;
}
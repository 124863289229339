/* .about{
    min-height: 80vh;
    background-color: rgb(153, 204, 186);
    overflow: hidden;
} */

.about {
    min-height: 80vh;
    background-color: rgb(153, 204, 186);
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
}

.about-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    z-index: 5;
    flex-wrap: wrap;
}

.about-info img {
    height: 250px;
    width:250px;
    border-radius: 50%;
    border: 3px solid var(--superdark);
    margin: 10px;
}

.about-words{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    border: 3px solid var(--superdark);
    margin: 10px;
    padding: 10px;
    font-size: large;
    min-width: 250px;
    width: 50%;
}
.projects{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--yellow);
    min-height: 80vh;
    padding-bottom: 25px;;
}

/* tv antennae */
.tv__top {
    width: 150px;
    margin: auto;
    position: relative;
}
.tv__antenna {
    width: 5px;
    height: 100px;
    background-color: var(--superdark);
    margin-bottom: -10px;
}
.tv__antenna--left {
    transform: rotate(-30deg);
    float: left;
}
.tv__antenna--right {
    transform: rotate(30deg);
    float: right;
}
.tv__antenna__base {
    height: 20px;
    background-color: var(--superdark);
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
    clear: both;
    position: relative;
}

/* tv */
.tv{
    border: 20px solid var(--superdark);
    border-radius: 30px;
    padding: 0;
    overflow: hidden;
    width: 500px;
    height: 300px;
}
.screen{
    background-color: var(--highlight);
    width: 500px;
    height: 300px;
}
.screen img{
    height: 350px;
    padding: 0;
}

/* tv buttons */
.project-buttons{
    margin-top: 15px;
    width: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

}
.project-buttons button{
    display: flex;
    align-items: center;
    height: 50px;
    width: 250px;
    background-color: var(--dark);
    border: solid 3px var(--superdark);
    border-radius: 20px;
    margin:5px;
}
.project-buttons button:hover{
    cursor: pointer;
}
.project-buttons .light{
    height: 30px;
    width: 30px;
    border: 3px solid var(--superdark);
    border-radius: 50%;
}
.project-buttons .on{
    background-color: yellow;
}
.project-buttons .off{
    background-color: cyan;
}
.project-buttons h3{
    font-weight: 400 ;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-left: 10px;
    color: var(--lighttext);
    font-size: 1.5rem;
}


.tv-screen-image{
    position: relative;
    animation-name: screenSaver;
    animation-duration: 12s;
    animation-iteration-count: infinite;
}

@keyframes screenSaver{
    0% {left: -5px; top: -5px}
    25% {left: -80px; top: -20px}
    50% {left: -25px; top: -12px}
    75% {left: -18px; top: -35px}
    100% {left: -1px; top: -1px}
}